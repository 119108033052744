import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { deletePermission, editPermission, getPermissionList } from '@/api/permission';
export default {
  name: "clientApis",
  data: function data() {
    return {
      data: [],
      queryParams: {
        type: 2,
        api_type: 2,
        method: "",
        parent_id: 0,
        keyword: "",
        page: 1,
        pageSize: 20
      },
      loading: false,
      total: 0,
      requestMethodMap: [{
        label: "GET",
        value: "GET"
      }, {
        label: "POST",
        value: "POST"
      }, {
        label: "PUT",
        value: "PUT"
      }, {
        label: "PATCH",
        value: "PATCH"
      }, {
        label: "DELETE",
        value: "DELETE"
      }],
      apiGroupMap: [],
      formLabelWidth: '120px',
      dialogNewVisible: false,
      submitting: false,
      editIndex: '',
      newForm: {
        id: 0,
        type: 2,
        path: "",
        name: "",
        parent_id: 0,
        method: ""
      },
      rules: {
        name: [{
          required: true,
          message: '接口名称 必填'
        }],
        method: [{
          required: true,
          message: '请求方式 必填'
        }],
        path: [{
          required: true,
          message: '路由 必填'
        }],
        parent_id: [{
          required: true,
          message: '分组 必填'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.requestData(1);
    getPermissionList({
      type: 2,
      api_type: 1,
      page: -1,
      pageSize: -1
    }).then(function (res) {
      if (res.code === 0) {
        _this.apiGroupMap = res.data.list;
      }
    });
  },
  methods: {
    requestData: function requestData(page) {
      var _this2 = this;

      if (page !== null) {
        this.queryParams.page = page;
      }

      this.loading = true;
      getPermissionList(_objectSpread({}, this.queryParams)).then(function (res) {
        _this2.loading = false;

        if (res.code === 0) {
          _this2.data = res.data.list;
          _this2.total = res.data.total;
        }
      });
    },
    handleDelete: function handleDelete(index, row) {
      var _this3 = this;

      this.$confirm('您确定要删除这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deletePermission({
          id: row.ID
        }).then(function (res) {
          if (res.code === 0) {
            _this3.$message.success('删除成功');

            _this3.data.splice(index, 1);
          }
        });
      }).catch(function () {
        _this3.$message.info('已取消删除');
      });
    },
    handleEdit: function handleEdit(index, row) {
      this.editIndex = '';

      if (row) {
        this.newForm = {
          id: row.ID,
          type: 2,
          path: row.path,
          name: row.name,
          parent_id: row.parentId,
          method: row.method
        };
        this.editIndex = index;
      } else {
        this.newForm = {
          id: 0,
          type: 2,
          path: "",
          name: "",
          parent_id: 0,
          method: ""
        };
      }

      this.dialogNewVisible = true;
    },
    handleSave: function handleSave(formName) {
      var _this4 = this;

      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var form = _objectSpread({}, _this4.newForm);

          editPermission(form).then(function (res) {
            _this4.submitting = false;

            if (res.code === 0) {
              _this4.$message.success('编辑成功');

              _this4.dialogNewVisible = false;

              if (_this4.editIndex === "") {
                _this4.data.unshift(res.data);
              } else {
                var arr = _this4.data.slice();

                arr[_this4.editIndex] = res.data;
                _this4.data = arr;
              }
            }
          });
        } else {
          _this4.submitting = false;
        }
      });
    }
  }
};